<template>
    <div class="Setting">
        <transition name="van-fade">
            <scroll v-if="!loading&&dataList.length>0" class="wrapper" ref="scroll" :pullDownRefresh="pullDownRefreshObj" :pullUpLoad="pullUpLoadObj" :startY="parseInt(startY)" @pullingDown="onPullingDown" @pullingUp="onPullingUp">
                <div class="buyLog">
                    <dl v-for="(item,index) in dataList" :key="index">
                        <div @click="goUrl(item)">
                            <dt>
                                <!-- 订单编号 -->
                                <span>{{$t('mx_mobile.Client.1585283168735')}}：{{item.orderno || '---'}}</span>
                                <em>{{item.createdate.split(' ')[0]}}</em>
                            </dt>
                            <dd>
                                <span>{{item.companyname}}</span>
                                <em class="wait" :class="item.isused == -2?'gray': item.isused == -1 ? 'red': ''">{{item.isusedName}}</em>
                                <!-- <em class="end" v-else>已开通</em> -->
                            </dd>

                            <dd v-if="item.desc.length > 0" style="flex-direction: column">
                                <span v-for="(ele,index) in item.desc" :key="index" class="desc">{{ele}}</span>
                            </dd>
                        </div>
                        <dd class="state">
                            <!-- 实付金额 -->
                            <div>{{$t('mx_mobile.Client.1585282263945')}}：<em>￥{{returnFloat(item.actualreceiveamount)}}</em></div>
                            <!-- 取消订单 -->
                            <van-button v-if="item.isused == -1" plain type="danger" @click="delItem(item)" size="small">{{$t('mx_mobile.Client.1585284750033')}}</van-button>
                        </dd>
                    </dl>
                </div>
            </scroll>
        </transition>
        <transition name="van-fade">
            <nodata v-if="dataList.length<=0&&!loading"></nodata>
        </transition>
        <transition name="van-fade">
            <loading v-if="loading" size="30px"></loading>
        </transition>
    </div>
</template>

<script>
import titleMixin from '../../../mixin/title'
import Scroll from '@/components/Scroll/index'
export default {
    name: 'Setting',
    mixins: [titleMixin],
    title: function fc() { return this.$t('mx_mobile.Client.1585639394231') }, // '我的订单'
    data() {
        return {
            dataList: [],
            pullDownRefresh: true,
            pullDownRefreshThreshold: 60,
            pullDownRefreshStop: 60,
            pullUpLoad: true,
            pullUpLoadThreshold: 0,
            pullUpLoadMoreTxt: this.$t('mx_mobile.Client.1585271749707'), // '加载更多'
            pullUpLoadNoMoreTxt: this.$t('mx_mobile.Client.1585271760385'), // '没有更多数据了'
            startY: 0,
            page: {
                total: 0,
                from: 1,
                size: 10
            },
            loading: false
        }
    },
    computed: {
        pullDownRefreshObj: function () {
            return this.pullDownRefresh ? {
                threshold: parseInt(this.pullDownRefreshThreshold),
                stop: parseInt(this.pullDownRefreshStop)
            } : false
        },
        pullUpLoadObj: function () {
            return this.pullUpLoad ? {
                threshold: parseInt(this.pullUpLoadThreshold),
                txt: { more: this.pullUpLoadMoreTxt, noMore: this.pullUpLoadNoMoreTxt }
            } : false
        }
    },
    created() {
        // this.pageUrl = this.$route.path
        this.reGetList()
    },
    methods: {
        async onPullingUp() {
            if (this.dataList.length >= this.page.total) {
                this.$refs.scroll.forceUpdate(false)
                return
            }
            if (this.dataList.length < this.page.total) {
                this.page.from += 1
                let dataList = await this.getDataList()
                this.dataList = this.dataList.concat(dataList)
                this.$nextTick(() => {
                    if (this.dataList.length >= this.page.total) {
                        this.$refs.scroll.forceUpdate(false)
                    } else {
                        this.$refs.scroll.forceUpdate(true)
                    }
                })
            }
        },
        async onPullingDown() {
            this.page.from = 1
            this.checkedItems = []
            this.dataList = await this.getDataList()
            this.$nextTick(() => {
                this.$refs.scroll.forceUpdate()
            })
        },
        returnFloat(x) {
            var value = Math.round(parseFloat(x) * 100) / 100
            var xsd = value.toString().split('.')
            if (xsd.length == 1) {
                value = value.toString() + '.00'
                return value
            }
            if (xsd.length > 1) {
                if (xsd[1].length < 2) {
                    value = value.toString() + '0'
                }
                return value
            }
        },
        goUrl(item) {
            this.$router.push('/my/orderDetail/' + item.orderno)
        },
        delItem(item) {
            this.axios.put(this.Global.config.apiBaseURL + this.Global.api.v2.payCenter_onlineOrder, { orderno: item.orderno }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    // console.log(res.data.data)
                    this.$toast(this.$t('mx_mobile.Client.1585284881312')) // '该订单取消成功'
                    this.reGetList()
                } else {
                    this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        async reGetList() {
            this.page.from = 1
            this.loading = true
            this.dataList = await this.getDataList()
            this.loading = false
        },
        async getDataList() {
            let listArr = []
            try {
                let data = {
                    orderno: '',
                    ordertype: '',
                    status: '',
                    beginTime: '',
                    endTime: '',
                    pageIndex: this.page.from,
                    pageSize: 10,
                    companyName: ''
                }
                let res = await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.mxOrder_mxOrderList, { params: data })

                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    // console.log(res.data.data)
                    listArr = res.data.data.dataList
                    if (listArr.length > 0) {
                        listArr.forEach(item => {
                            item.desc = item.desc.split(',')
                        })
                    }
                    this.page.total = res.data.data.resultCount
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                console.log(error)
            }
            return listArr
        }
    },
    watch: {
        // $route(to, from) {
        //     if (to.path === this.pageUrl) {
        //         this.setMenu()
        //     }
        // }
        pullDownRefreshObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullDown()
                } else {
                    scroll.closePullDown()
                }
            },
            deep: true
        },
        pullUpLoadObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullUp()
                } else {
                    scroll.closePullUp()
                }
            },
            deep: true
        }
    },
    components: {
        'Scroll': Scroll
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
.Setting {
    position: fixed;
    left: 0;
    right: 0;
    top: 3.2em;
    bottom: 0;
    overflow-y: auto;
}
.buyLog {
    margin-bottom: 15px;
    > dl {
        background: #fff;
        padding: 10px 15px;
        margin: 10px 0 0;
        color: #909399;
        dt,
        dd {
            display: flex;
            justify-content: space-between;
            margin: 8px 0;
        }
        dt {
            color: #303133;
            font-size: 14px;
        }
        .state {
            border-top: 1px solid #eee;
            padding: 10px 0 0;
            margin: 15px 0 0;
            > div {
                line-height: 30px;
                em {
                    color: #d0021b;
                }
            }
        }
        .wait {
            font-weight: bold;
            color: #303133;
        }
        .red {
            color: #d0021b;
        }
        .gray {
            color: #bcbcbc;
        }
        .desc {
            display: block;
        }
    }
}
</style>
